@tailwind base;

@tailwind components;

@tailwind utilities;

/* * {
  box-sizing: border-box;
  color: #333;
}

body {
  background-color: #81a69b;
  background-image: url(http://static.frontendmasters.com/resources/2019-05-02-complete-intro-react-v5/image-background.png);
  margin: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
*/
.select-dropdown {
  @apply block appearance-none w-full bg-white border border-gray-400 px-4 py-2 pr-8 rounded shadow leading-tight;
}

.select-dropdown:hover {
  @apply border-gray-500;
}

.select-dropdown:focus {
  @apply outline-none ring;
}

.mapping-submit-btn {
  @apply border border-transparent font-medium w-full px-4 py-2 text-lg transition duration-150 ease-in-out;
}

.mapping-submit-btn--active {
  @apply bg-purple-700 text-white;
}

.mapping-submit-btn--active:focus {
  @apply outline-none border-purple-500;
}

.mapping-submit-btn--active:hover {
  @apply bg-twc-logo;
}

.mapping-submit-btn--active:active {
  @apply bg-twc-logo;
}

.mapping-submit-btn--disabled {
  @apply bg-gray-500 opacity-50 cursor-not-allowed text-gray-600;
}

.mapping-submit-btn--disabled:focus {
  @apply outline-none border-gray-500;
}

.mapping-submit-btn--disabled:hover {
  @apply bg-gray-500;
}

.mapping-submit-btn--disabled:active {
  @apply bg-gray-500;
}

.upload-submit-btn {
  @apply w-full border border-transparent font-medium p-3 text-base text-white transition duration-150 ease-in-out flex justify-center;
}

.upload-submit-btn--active {
  @apply bg-twc-gold;
}

.upload-submit-btn--active:focus {
  @apply outline-none bg-twc-gold;
}

.upload-submit-btn--active:hover {
  @apply bg-twc-gold;
}

.upload-submit-btn--active:active {
  @apply bg-twc-gold;
}

.upload-submit-btn--disabled {
  @apply bg-gray-500 opacity-50 cursor-not-allowed;
}

.upload-submit-btn--disabled:focus {
  @apply outline-none border-gray-500;
}

.upload-submit-btn--disabled:hover {
  @apply bg-gray-500;
}

.upload-submit-btn--disabled:active {
  @apply bg-gray-500;
}

.dropzone {
  @apply bg-gray-100 text-gray-500 cursor-pointer flex flex-1 flex-col items-center p-5 border-2 border-gray-400 border-dashed outline-none rounded-sm;
}

.dropped-filename {
  @apply my-3 inline-block rounded-full font-semibold relative py-2 px-4 text-twc-btn-login-100 border-twc-btn-login-100 border-2;
}

.bg-purple-twc {
  background-color: #1d173a;
}

.btn-disabled {
  @apply cursor-not-allowed bg-gray-400;
}

.btn-enabled {
  @apply bg-purple-700;
}

.btn-enabled:hover {
  @apply bg-purple-900;
}

/*===============================================================*/

/* RADIO BUTTONS */

input[type="radio"] + label {
  @apply text-twc-logo border border-transparent;
}
input[type="radio"]:checked + label {
  @apply text-white bg-twc-logo border border-transparent;
}

input[type="radio"] + label span {
  transition: background 0.2s, transform 0.2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
}

input[type="radio"]:checked + label span {
  @apply bg-twc-logo;
  /* box-shadow: 0px 0px 0px 2px white inset; */
}

/* input[type="radio"]+label:hover {
  @apply border border-gray-400;
} */

input[type="radio"] + .gold-button {
  @apply text-base text-white border-2 bg-twc-btn-login-100 border-twc-btn-login-100;
}

input[type="radio"] + .gold-button:hover {
  @apply text-base text-white border-2 bg-twc-btn-login-200 border-twc-btn-login-200;
}

input[type="radio"]:checked + .gold-button {
  @apply text-base text-white bg-twc-logo border-2 border-twc-logo;
}

/*===============================================================*/

.fade-enter .page {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active .page {
  opacity: 1;
  transition: opacity 450ms ease-in;
}

.fade-exit.fade-exit-active .page {
  display: none;
  opacity: 0;
  transition: opacity 10ms ease-in;
}

.animate-opacity-0 {
  opacity: 0;
  transform: translateY(100px);
  height: 0;
}

.animate-opacity-0.animate-up {
  opacity: 1;
  height: inherit;
  transform: translateY(0px);
  transition: opacity 0.65s cubic-bezier(0.165, 0.84, 0.44, 1) 0.15s,
    height 0.65s cubic-bezier(0.165, 0.84, 0.44, 1) 0.15s,
    transform 0.65s cubic-bezier(0.165, 0.84, 0.44, 1) 0.15s;
}

table.table-inventory tbody td.expand-td {
  @apply whitespace-normal;
}

table.table-inventory tbody td {
  @apply border px-4 py-2 cursor-pointer truncate;
  max-width: 250px;
}

table.table-inventory thead th {
  @apply px-4 py-2;
}

table.reviews-table td {
  @apply border-b border-white;
  padding: 0.1875rem 0;
}

table.reviews-table th {
  @apply py-1;
}

.mapped-fixed {
  @apply fixed inset-x-0 top-0 m-auto z-10;
}

.mapped-fixed > div {
  max-width: 81.5%;
  @apply bg-gray-200 p-4 m-auto;
}

dl {
  @apply my-4 pl-3;
}

dt {
  @apply font-semibold;
}

dd {
  @apply ml-6;
}

.thumbs-container {
  @apply flex mt-4 flex-row flex-wrap;
}

.thumb-inner {
  @apply relative flex flex-grow items-center justify-center;
  min-width: 0px;
  overflow: hidden;
}

.img-uploaded .thumb-inner:after {
  @apply flex items-center justify-center text-white absolute inset-0 text-2xl font-semibold;
  content: "UPLOADED";
  background: #4c866099;
  text-shadow: 1px 1px 3px black;
  letter-spacing: 1px;
}

.thumb {
  @apply flex flex-col justify-between mb-4 box-border p-1 rounded-sm w-full;
  border: 1px solid rgb(234, 234, 234);
}

.img-uploaded .rm-img {
  display: none;
}

@media (min-width: 768px) {
  .thumb {
    width: 49%;
    margin-right: 2%;
  }

  .thumb:nth-child(2n) {
    margin-right: 0;
  }
}

@media (min-width: 1024px) {
  .thumb {
    width: 32%;
  }

  .thumb:nth-child(2n) {
    margin-right: 2%;
  }

  .thumb:nth-child(3n) {
    margin-right: 0;
  }
}

.expand-source {
  display: block;
  text-transform: none;
  transition: max-height 0.3s;
  overflow: hidden;
  will-change: max-height;
}

.imclosed + .expand-source {
  max-height: 0;
  visibility: hidden;
}

.imopened + .expand-source {
  overflow: visible;
}

@keyframes fadeInThenOut {
  0% {
    opacity: 0;
  }

  25%,
  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

#copied-to-cb {
  opacity: 0;
}

#copied-to-cb.show-hide {
  animation: fadeInThenOut 1.5s 1;
}

.react-hooks-paginator {
  @apply flex-wrap;
}

.latest-reviews-table td,
.latest-reviews-table th {
  @apply px-2;
}

.latest-reviews-sources-table tbody tr:hover,
.latest-reviews-table tbody tr:hover {
  @apply bg-gray-100 cursor-pointer;
}

.latest-reviews-sources-table tbody tr,
.latest-reviews-table tbody tr {
  @apply border-b;
}

.jump-to-btn {
  @apply whitespace-nowrap bg-transparent text-blue-700 p-1 border border-blue-500 rounded text-sm;
}

.jump-to-btn:hover {
  @apply bg-blue-500 text-white border-transparent;
}

.close-update-value-btn {
  @apply bg-green-600 inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm transition ease-in-out duration-150;
}
.close-update-value-btn:hover {
  @apply bg-green-500;
}
.close-update-value-btn:focus {
  @apply outline-none border-green-700;
}

.close-modal-btn {
  @apply bg-red-600 inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm transition ease-in-out duration-150;
}

.close-modal-btn:hover {
  @apply bg-red-500;
}

.close-modal-btn:focus {
  @apply outline-none border-red-700;
}

/* TABLE STYLE: */

.table-container {
  /* display: flex; */
  margin: 1em 2em;
  font-size: 0.85rem;
}
thead {
  @apply bg-twc-blue;
  color: #fafafa;
}

table {
  border-spacing: 0;
  border: 1px solid #b59062;
}
table tr:last-child td {
  border-bottom: 0;
}
table th,
table td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #b59062;
  border-right: 1px solid #b59062;
  position: relative;
}
table th:last-child,
table td:last-child {
  border-right: 0;
}
table tr:nth-child(even) {
  background-color: #fafafa;
}

table th::before {
  position: absolute;
  right: 15px;
  top: 16px;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
input {
  padding: 10px;
  margin-bottom: 20px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: none;
}
.select-search__select {
  height: 200px;
  @apply overflow-y-scroll;
}
.select-search__value input {
  @apply my-2 w-full;
}
.select-search__select li button {
  width: 100%;
  text-align: left;
  padding: 2px 5px;
}
.select-search__select li.is-selected,
.select-search__select li button:hover {
  background-color: #d6c2a8;
}

/* table.error-table {
  text-align: left;
  position: relative;
  border-collapse: collapse;
} */
/* https://css-tricks.com/position-sticky-and-table-headers/ */
table.error-table th {
  @apply bg-twc-blue;
  z-index: 1;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

table.error-table .updated-row td {
  @apply bg-green-100;
}
